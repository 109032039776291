const { API_URL } = process.env;
const apiStgAndProd = 'https://finalyticsdata.com/api/v1';

export const API_ENVIRONMENT = Object.freeze({
  dev: 'https://devfinalyticsdata.com/api/v1',
  stg: apiStgAndProd,
  prod: apiStgAndProd,
  devQ: 'https://devfinalyticsdata.com/api/v1',
  stgQ: 'https://stgfinalyticsdata.com/api/v1',
  prodQ: 'https://finalyticsdata.com/api/v1',
  clienteApi: 'http://127.0.0.1:8001/api/v1',
  local: 'http://127.0.0.1:8001/api/v1',
});

export class ApiUrlService {
  static instance;
  urlParams = new URLSearchParams(window.location.search);
  itemsApiStorage = [
    { name: 'v', value: this.urlParams.get('v') },
    {
      name: 'api',
      value: this.urlParams.get('api'),
    },
    {
      name: 'pixel',
      value: this.urlParams.get('pixel'),
    },
    {
      name: 'debug_ads',
      value: this.urlParams.get('debug_ads'),
    },
    {
      name: 'session_referring_url',
      value: this.getSessionReferringUrl(),
    }
  ];
  customerTestEnvironment = ['http://localhost:8000', 'http://127.0.0.1:8000'];
  hostEnv = window.hostEnv;

  constructor() {
    if (!ApiUrlService.instance) {
      this.setInitialSessionReferringUrl();
      ApiUrlService.instance = this;
    }
    return ApiUrlService.instance || (ApiUrlService.instance = this);
  }

  setInitialSessionReferringUrl() {
    if (!sessionStorage.getItem('session_referring_url')) {
      sessionStorage.setItem('session_referring_url', window.location.href);
    }
    if (!localStorage.getItem('original_referring_url')) {
      localStorage.setItem('original_referring_url',  window.location.href);
    }
  }

  getSessionReferringUrl() {
    return sessionStorage.getItem('session_referring_url');
  }

  customerEnvironment() {
    return (
      this.customerTestEnvironment.includes(window.location.origin) &&
      API_ENVIRONMENT.local
    );
  }

  setSessionData() {
    this.itemsApiStorage.forEach(
      (setValue) =>
        setValue.value && sessionStorage.setItem(setValue.name, setValue.value)
    );
  }

  removeSessionStorageData() {
    this.itemsApiStorage.forEach(
      (item) => sessionStorage.removeItem(item.name)
    );
  }

  getApiUrl() {
    this.setSessionData();
    const queryParamApi = sessionStorage.getItem('api');

    return (
      this.customerEnvironment() ||
      API_ENVIRONMENT[`${queryParamApi}Q`] ||
      API_ENVIRONMENT[this.hostEnv] ||
      API_URL ||
      API_ENVIRONMENT.stg
    );
  }
}
