/**
 * Debugger Tool
 * @param msg
 * @param ctx
 */
export const debuggerLog = (msg, ctx) => {
  if (window.finDebug) {
    // eslint-disable-next-line no-console
    console.log(msg, ctx || '');
  }
};
export const debuggerLogError = (msg, ctx) => {
  if (window.finDebug) {
    // eslint-disable-next-line no-console
    console.error(msg, ctx || '');
  }
};