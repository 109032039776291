import * as localForage from 'localforage';
import { encrypt, decrypt } from '../utils';
import { debuggerLogError } from '../utils/debuggerLog';

export const FORAGE_KEY = Object.freeze({
  INITIALIZE: 'initialize',
  SESSION_DATA: 'sessionData',
  LAST_DATA: 'lastData',
});

class LocalForageOPC {
  async setItem(name, data) {
    await localForage.setItem(name, data);
  }

  async getItem(name) {
    return localForage.getItem(name);
  }

  async clear() {
    await localForage.clear();
  }
}

export class LocalForageService {
  static instance;
  isProduction = !window.finDebug;
  localForage = new LocalForageOPC();

  constructor() {
    return LocalForageService.instance || (LocalForageService.instance = this);
  }

  encryptData(data) {
    return encrypt(data);
  }

  async decryptData(nameForage) {
    const encryptedData = await this.localForage.getItem(
      `${nameForage}_encrypted`
    );
    return encryptedData && decrypt(encryptedData);
  }

  async setForage(nameForage, data) {
    try {
      if (this.isProduction) {
        await this.localForage.setItem(
          `${nameForage}_encrypted`,
          this.encryptData(data)
        );
      } else {
        await this.localForage.setItem(nameForage, data);
      }
    } catch (error) {
      debuggerLogError('[Local Forage Error]', error);
    }
  }

  async getForage(nameForage) {
    try {
      if (this.isProduction) {
        return await this.decryptData(nameForage);
      } else {
        return this.localForage.getItem(nameForage);
      }
    } catch (error) {
      debuggerLogError('[Local Forage Error]', error);
    }
  }

  async clearForage() {
    await this.localForage.clear();
  }
}
