import { debuggerLog } from '../utils/debuggerLog';
import { HttpsService, TYPE_HTTPS } from './HttpsService';

const TYPE_EVENT = Object.freeze({
  USER_INTERACTION: 'user_interaction',
  PAGE_LOAD: 'page_load',
});

const getFullUrl = (hostEnvSetting) => {
  let fullUrl = '';
  if (hostEnv === 'local') {
    fullUrl = 'http://127.0.0.1:8001/api/v1/analytics/';
  } else if (hostEnv === 'prod') {
    fullUrl = 'https://finalyticsdata.com/api/v1/analytics/';
  } else {
    fullUrl = `https://${hostEnvSetting}finalyticsdata.com/api/v1/analytics/`;
  }
  return fullUrl;
};

export class AnalyticsService {
  static instance;
  api = new HttpsService();
  dataPageLoad = [];
  userInteraction = [];
  cu_id = window.finalytics.clientId;
  hostEnvSetting = window.hostEnv || '';
  fullUrl = getFullUrl(this.hostEnvSetting);
  constructor() {
    return AnalyticsService.instance || (AnalyticsService.instance = this);
  }

  async sendToFin(data) {
    this.api.addToQueue({
      type: TYPE_HTTPS.POST,
      payload: {
        payload: {
          data,
        },
      },
      fullUrl: this.fullUrl,
    });
  }

  sendPageLoad() {
    let doNotDupe = [];
    if (!this.dataPageLoad.length) return;
    const ad = this.dataPageLoad
      .map((ad) => {
        if (!window.finalytics.adsReplaced.includes(ad.id) || doNotDupe.indexOf(ad.id) > 0) return;
        doNotDupe.push(ad.id);
        return ad;
      })
      .filter(Boolean);
    //debuggerLog('[AnalyticsService] window.finalytics.adsReplaced adsR includes', window.finalytics.adsReplaced.includes(ad.id));
    //debuggerLog('[AnalyticsService] window.finalytics.adsReplaced donotdupe', doNotDupe.indexOf(ad.id));
    debuggerLog('[AnalyticsService] sendPageLoad window.finalytics.adsReplaced', ad);
    return Promise.all(ad.map((data) => this.sendToFin(data)));
    //debuggerLog('[AnalyticsService] window.finalytics.adsReplaced do not dupe', doNotDupe);
  }

  sendUserInteraction(data) {
    const sendUserInteraction = localStorage.getItem('sendUserInteraction');
    if (!sendUserInteraction)
      return localStorage.setItem(
        'sendUserInteraction',
        JSON.stringify([data])
      );
    localStorage.setItem(
      'sendUserInteraction',
      JSON.stringify([...JSON.parse(sendUserInteraction), data])
    );
  }

  getDataFinID = (str) => {
    return str.split('_').at(-1);
  };

  getUserInteractionByID(id) {
    return this.userInteraction.find((data) => data.id === id);
  }

  getCampaignType(id) {
    const campaign = window.finalytics.ads.find(
      (ad) => ad.id === id && ad.campaign && ad.campaign.campaign_type
    );
    return (
      (campaign && campaign.campaign && campaign.campaign.campaign_type) || null
    );
  }

  sendToSaveSendUserInteraction() {
    const sendUserInteraction = localStorage.getItem('sendUserInteraction');
    localStorage.removeItem('sendUserInteraction');
    if (!sendUserInteraction) return;
    return Promise.all(
      JSON.parse(sendUserInteraction).map((data) => this.sendToFin(data))
    );
  }

  processAd(adDetails) {
    const { id, event, campaigns, div_id, div_class, div_tracking_label, core_product, name, algorithms,is_member, ...res } = adDetails;
    const idGtm = res['gtm.uniqueEventId'];
  
    return {
      event: event || 'Finalytics-JO',
      id,
      campaigns,
      cu_id: this.cu_id,
      campaign_type: this.getCampaignType(id),
      div_id,
      div_class,
      div_tracking_label,
      ['gtm.uniqueEventId']: idGtm,
      algorithms,
      page: window.location.pathname,
      core_product,
      name,
      is_member: is_member
    };
  }

  setDataPageLoadAndInteraction(tagsAdsReplaced) {
    debuggerLog('[AnalyticsService] setDataPageLoadAndInteraction tagsAdsReplaced', { tagsAdsReplaced });
    const memberTimestamp = localStorage.getItem('memberTimestamp') || '';
    let productsOwnedProcessed = [];
    try {
      productsOwnedProcessed = JSON.parse(localStorage.getItem('productsOwnedProcessed')) || [];
    } catch (e) {
      console.error('Error parsing productsOwnedProcessed from localStorage:', e);
    }

    const isMember = !!memberTimestamp || productsOwnedProcessed.length > 0;
    debuggerLog('[AnalyticsService] windowDataLayer', { dataLayer: window.dataLayer  });
    const adsData = window.dataLayer?.filter((ga) => ga.id) || tagsAdsReplaced || [];
    debuggerLog('[AnalyticsService] adsData', { adsData });

    adsData.forEach((ad) => {
      const adDetails = ad.adRes || ad;
      const baseObje = {
        ...this.processAd(adDetails),
        is_member: isMember 
      };
      debuggerLog('[AnalyticsService] baseObje', { baseObje });
  
      this.dataPageLoad.push({
        ...baseObje,
        event_type: TYPE_EVENT.PAGE_LOAD,
        env: this.hostEnvSetting,
      });
  
      this.userInteraction.push({
        ...baseObje,
        event_type: TYPE_EVENT.USER_INTERACTION,
        env: this.hostEnvSetting,
      });
    });
  }
  
  addEventListenerGA(id) {
    debuggerLog('[AnalyticsService] addEventListenerGA:', id);
    document.querySelectorAll(`[data-fin="ad:id__${id}"]`).forEach((ad) =>
      ad.addEventListener('click', () => {
        const data = this.getUserInteractionByID(id);
        if(window.dataLayer) {
          window.dataLayer.push({
            ...data,
            env: this.hostEnvSetting,
            user_interaction_element: ad.tagName,
            interaction_element_text: ad.textContent,
          });
        }
        const updatedData = {
          ...data,
          link_label: ad.textContent,
        };
        //if (window.location.pathname.includes('/olb')) {
        //  this.sendToFin(data);
        //} else {
        //  this.sendUserInteraction(data);
        //}
        this.sendUserInteraction(updatedData);
        this.sendToFin(updatedData);
      })
    );
  }

  analyticsAdsReplaced() {
    let doNotDupe = [];
    if (window && window.finalytics && window.finalytics.adsReplaced &&  window.finalytics.adsReplaced.length > 0) {
      debuggerLog('[AnalyticsService] analyticsAdsReplaced: adsreplaced before loop', window.finalytics.adsReplaced);
      window.finalytics.ads.forEach(
        ({ id, div_id, core_product, geo, ...ad }) => {
          //debuggerLog('[AnalyticsService] analyticsAdsReplaced: ad.id', id);
          //debuggerLog('[AnalyticsService] analyticsAdsReplaced: doNotDupe', doNotDupe);
          //debuggerLog('[AnalyticsService] analyticsAdsReplaced: !disable', !ad.disable_tracking);
          //debuggerLog('[AnalyticsService] analyticsAdsReplaced: ad in adsreplaced', window.finalytics.adsReplaced.indexOf(id));
          //debuggerLog('[AnalyticsService] analyticsAdsReplaced: ad in doNotDupe', doNotDupe.indexOf(id));
          if (!ad.disable_tracking && window.finalytics.adsReplaced.indexOf(id) >= 0 && doNotDupe.indexOf(id) < 0) {
            debuggerLog('[AnalyticsService] analyticsAdsReplaced: send', id);
            let adToPush = {
              event: 'Finalytics-JO',
              event_type: TYPE_EVENT.PAGE_LOAD,
              id,
              div_id,
              page: window.location.href,
              segments: [],
              core_product,
              cu_id: this.cu_id,
              env: this.hostEnvSetting,
              replacements: {
                geo: {
                  span: geo || '',
                },
              },
              ...ad,
            };
            adToPush.ad_html = '';
            //debuggerLog('[AnalyticsService] analyticsAdsReplaced: adToPush', adToPush);
            if (window.dataLayer) {
              window.dataLayer.push(adToPush);
            }
            doNotDupe.push(id);
            //this.addEventListenerGA(id);
          }
        }
      );
    }
    debuggerLog('[AnalyticsService] analyticsAdsReplaced: sent2', doNotDupe);
    //debuggerLog('[AnalyticsService] analyticsAdsReplaced: sent3', window.finalytics.adsReplaced);
    debuggerLog('[AnalyticsService] analyticsAdsReplaced: datalayer', window.dataLayer);
  }

  analyticsInit() {
    debuggerLog('[AnalyticsService] : analyticsInit in fun window.finalytics', window.finalytics);
    if (window && window.finalytics && window.finalytics.ads && window.finalytics.ads.length) {
      debuggerLog('[AnalyticsService] : analyticsInit window.finalytics.ads', window.finalytics);
      window.finalytics.ads.forEach(
        ({ id, div_id, core_product, geo, ...ad }) => {
          if (!ad.disable_tracking) {
            //window.dataLayer.push({
            //  event: 'Finalytics-JO',
            //  event_type: TYPE_EVENT.PAGE_LOAD,
            //  id,
            //  div_id,
            //  page: window.location.href,
            //  segments: [],
            //  core_product,
            //  cu_id: this.cu_id,
            //  env: this.hostEnvSetting,
            //  replacements: {
            //    geo: {
            //      span: geo || '',
            //    },
            //  },
            //  ...ad,
            //});
            this.addEventListenerGA(id);
          }
        }
      );
    }
  }

  async executeAdsReplaced(tagsAdsReplaced) {
    debuggerLog('[AnalyticsService] tagsAdsReplaced:', {tagsAdsReplaced});
    this.analyticsAdsReplaced();
    //this.analyticsInit();
    this.setDataPageLoadAndInteraction(tagsAdsReplaced);
    this.sendToSaveSendUserInteraction();
    this.sendPageLoad();
  }

  async execute() {
    this.analyticsInit();
    //this.setDataPageLoadAndInteraction();
    //this.sendToSaveSendUserInteraction();
    //this.sendPageLoad();
    debuggerLog('[AnalyticsService]', this);
  }

  executeAll() {
    this.execute();
    this.executeAdsReplaced();
  }
}
